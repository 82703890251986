var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("dataset", {
        attrs: { "ds-data": _vm.logs },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var ds = ref.ds
              return [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", {}, [
                      _c(
                        "table",
                        { staticClass: "table table-hover d-md-table" },
                        [
                          _c("thead", [
                            _c(
                              "tr",
                              _vm._l(_vm.cols, function(th) {
                                return _c(
                                  "th",
                                  {
                                    key: th.field,
                                    style:
                                      th.field == "objet" ? "width: 20%;" : ""
                                  },
                                  [_vm._v(" " + _vm._s(th.name) + " ")]
                                )
                              }),
                              0
                            )
                          ]),
                          _c("dataset-item", {
                            attrs: { tag: "tbody" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var rowIndex = ref.rowIndex
                                    return [
                                      _c("tr", { key: rowIndex }, [
                                        _c("td", [
                                          _vm._v(_vm._s(rowIndex + 1))
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(row.full_name))
                                        ]),
                                        _c("td", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void()"
                                              }
                                            },
                                            [_vm._v(_vm._s(row.ip))]
                                          )
                                        ]),
                                        _c("td", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void()"
                                              }
                                            },
                                            [_vm._v(_vm._s(row.browser))]
                                          )
                                        ]),
                                        _c("td", [_vm._v(_vm._s(row.date))]),
                                        _c("td", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(row.action.html)
                                            }
                                          })
                                        ])
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-md-row flex-column justify-content-between align-items-center"
                  },
                  [
                    _c("dataset-show", { attrs: { "ds-show-entries": 5 } }),
                    _c("dataset-pager")
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }